import {controller, target} from '@github/catalyst'
import {announceFromElement} from '@github-ui/aria-live'

const maxInput = document.querySelector("input[name='maximum_runners']") as HTMLInputElement

@controller
class RunnerScalingElement extends HTMLElement {
  // Max runner input
  @target maxInput: HTMLInputElement
  @target maxGroup: HTMLElement
  @target maxTooLowErrorMessage: HTMLElement
  @target maxTooHighErrorMessage: HTMLElement
  @target maxGpuTooHighErrorMessage: HTMLElement
  @target maxNote: HTMLElement

  disableSubmitButton() {
    const submitButton = document.querySelector<HTMLButtonElement>('.js-submit-custom-hosted-runner-button')!
    submitButton.disabled = true
  }

  enableSubmitButton() {
    const submitButton = document.querySelector<HTMLButtonElement>('.js-submit-custom-hosted-runner-button')!
    submitButton.disabled = false
  }

  validateMax() {
    this.resetMaxState()

    if (!this.maxInput.value) return

    const maxValue: number = +this.maxInput.value

    const maxInputUpperLimit = parseInt(maxInput.max)
    const maxInputLowerLimit = parseInt(maxInput.min)

    // MAX_GPU_RUNNERS_UPPER_LIMIT
    const gpuRunnersMaxLimit = 20

    // Maximum input is too low
    if (maxValue < maxInputLowerLimit) {
      this.maxGroup.classList.add('errored')
      this.maxTooLowErrorMessage.hidden = false
      announceFromElement(this.maxNote)
      this.disableSubmitButton()
      return
    }

    // Maximum input is too high
    if (maxValue > maxInputUpperLimit) {
      this.maxGroup.classList.add('errored')
      if (maxInputUpperLimit === gpuRunnersMaxLimit) {
        this.maxGpuTooHighErrorMessage.hidden = false
        this.maxTooHighErrorMessage.hidden = true
      } else {
        this.maxGpuTooHighErrorMessage.hidden = true
        this.maxTooHighErrorMessage.hidden = false
      }
      announceFromElement(this.maxNote)
      this.disableSubmitButton()
      return
    }
  }

  resetMaxState() {
    this.maxTooHighErrorMessage.hidden = true
    this.maxTooLowErrorMessage.hidden = true
    this.maxGpuTooHighErrorMessage.hidden = true
    this.maxGroup.classList.remove('errored')
    this.enableSubmitButton()
  }
}
